import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

//import { loadL10nDB } from "some-library";
//import { L10n } from "some-other-library";
const formatters = {};

const loaded = {};
const getL10nData = (languages, callback) => {
    const promise = Promise.all(languages.map(async (lng) => {
        if (lng !== "es" && lng !== "es-MX") return;
        if (!loaded[lng]) {
            // if you need to load a localized database, do it here
            //const { localizedData } = await import(`./i18n_${lng}.js`);
            loaded[lng] = true;
            //      loadL10nDB(localizedData);
        }
    })).catch(console.error);

    return (err, ret) => promise.then(() => callback(err, ret));
};

/* Use i18next-http-backend, while also loading L10n data */
class CustomBackend extends Backend {
    constructor(services, options, allOptions) {
        super(services, options, allOptions);
    }

    readMulti(languages, namespaces, callback) {
        const promiseCallback = getL10nData(languages, callback);
        super.readMulti(languages, namespaces, promiseCallback);
    }

    read(language, namespace, callback) {
        const promiseCallback = getL10nData([language], callback);
        super.read(language, namespace, promiseCallback);
    }
}

const defaultLocale = new Intl.DateTimeFormat().resolvedOptions().locale;

let formatterCache = {};
const getDateFormatter = (locale, type) => {
    let lang = locale || "en";
    if (defaultLocale.toUpperCase().startsWith(lang.toUpperCase())) lang = defaultLocale;
    const cKey = lang + type;

    let fmt = formatterCache[cKey];
    if (!fmt) {
        let options;
        if (type === "long") {
            options = { year: "numeric", month: "long", day: "numeric", timeZone: "utc" };
        } else if (type === "daily") {
            options = { month: "short", day: "numeric", timeZone: "utc" };
        } else if (type === "monthly") {
            options = { month: "short", year: "2-digit", timeZone: "utc" };
        } else return null;

        fmt = new Intl.DateTimeFormat(lang, options);
        formatterCache[cKey] = fmt;
    }
    return fmt;
};

const localeDateFormat = (locale, type, date) => {
    const formatter = getDateFormatter(locale, type);
    return formatter ? formatter.format(date) : null;
};

const numberFormat = Intl.NumberFormat(undefined, { useGrouping: true, maximumFractionDigits: 4 });
const numericDateFormat = Intl.DateTimeFormat(undefined, { year: "numeric", month: "2-digit", day: "2-digit", timeZone: "utc" });
const timeDateFormat = Intl.DateTimeFormat(undefined, { year: "numeric", month: "2-digit", day: "2-digit",
    hour: "2-digit", minute: "2-digit", timeZone: "utc"
});
const timeFormat = Intl.DateTimeFormat(undefined, { hour: "2-digit", minute: "2-digit", timeZone: "utc" });

const onChange = {
    type: "3rdParty",

    init(i18n) {
        i18n.on("languageChanged", (lng) => {
            formatters.number = numberFormat;
            // these 6 takes the date as UTC
            formatters.time = timeFormat;
            formatters.timeDate = timeDateFormat;
            formatters.numericDate = numericDateFormat;
            formatters.longDate = { format: (date) => localeDateFormat(lng, "long", date) };
            formatters.dailyDate = { format: (date) => localeDateFormat(lng, "daily", date) };
            formatters.monthlyDate = { format: (date) => localeDateFormat(lng, "monthly", date) };

            globalThis.document.documentElement.setAttribute("lang", lng);

            // if you need to update the l10n data of an external library with our translations
            /*
            const params = {};
            params[lng] = {
                someComponent: {
                    someLabel: i18n.t("some.label")
                }
            };
            L10n.load(params);
            */
        });
    },
};

i18n
// load translation using http -> see /public/locales
// learn more: https://github.com/i18next/i18next-http-backend
    .use(CustomBackend)
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
// we use a plugin for this to be sure it executes before react-i18next
    .use(onChange)
// pass the i18n instance to react-i18next.
    .use(initReactI18next)
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        detection: {
            order: ["querystring", "cookie", "localStorage", "sessionStorage", "navigator"],
        },
        fallbackLng: (code) => {
            if (!code) return ["en"];
            const fallbacks = [code];

            // add base lang
            const baseLang = code.split("-")[0];
            if (baseLang !== code) fallbacks.push(baseLang);

            // last fallback to english
            if (baseLang !== "en") fallbacks.push("en");
            return fallbacks;
        },
        supportedLngs: ["en", "es"/*, 'es-MX'*/],
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format: (value, format, _ignored_lng) => {
                if (format === "number") return (value !== undefined && value !== null) ? formatters.number.format(value) : null;
                if (format === "number_unit") {
                    const { number, unit } = value;
                    return (number !== undefined && number !== null) ? `${formatters.number.format(number)} ${unit || ""}` : null;
                }
                if (format === "date_format") {
                    const { date, format } = value;
                    if (date === undefined || date === null) return null;

                    let formatter;
                    if (["time", "timeDate", "numericDate", "longDate", "dailyDate", "monthlyDate"].includes(format)) {
                        formatter = formatters[format];
                    } else formatter = formatters.timeDate;

                    return formatter.format(date);
                }
                return value;
            }
        }
    /* react: {
      useSuspense: false
    } */
    });

export default i18n;
