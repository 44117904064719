import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useRouteError } from "react-router";

import { Button } from "primereact/button";

import { AuthenticatorContext } from "../../contexts/AuthenticatorContext";

const HTTP_FORBIDDEN_CODE = 403;

const NoAccess = () => {
    const error = useRouteError();

    const { t } = useTranslation();
    const { signOut } = useContext(AuthenticatorContext);

    const isForbidden = error.response?.status === HTTP_FORBIDDEN_CODE;
    return (
        <div className="No-access">
            {isForbidden ? t("header.no_access") : error.message}
            <Button label={t("header.signout")} aria-label={t("header.signout")} onClick={() => signOut()} />
        </div>
    );
};

export default NoAccess;
