import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Auth } from "@aws-amplify/auth";

const MIN_PASS_LENGTH = 8;

const Login = ({ dcEmail }) => {
    const { t } = useTranslation();

    //datos del login
    const [userEmail, setUser] = useState(dcEmail || "");
    const [userPass, setPassword] = useState(" ");

    //mensaje de error y var para renderizar login o formForgotpass
    const pHolderCode = t("login.pHolderCode");
    const pHolderPass = t("login.pHolderPass");
    const pHolderPassConfirm = t("login.pHolderPassConfirm");
    const changePassword = t("login.changePassword");

    const [showPassForNewUser, setShowPassForNewUser] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    const [showUserState, setShowUserState] = useState({
        showInvalidUser: false,
        showMsgEmail: false,
        showMsgPass: false,
    });

    const [showState, setShowState] = useState({
        showUserNotFound: false,
        showCodeSent: false,
        showEnterCode: false,
        showInvalidCode: false,
        showPassLength: false,
        showPassMatch: false,
        showCodeException: false,
        showExpiredCodeException: false,
        showLimitExceededException: false,
        showInvalidParameterException: false,
        showUserNotFoundException: false,
        showDefaultError: false,
        showPassChanged: false,
    });

    const [showLoginState, setShowLoginState] = useState({
        showLogin: true,
        showLoginForgot: false,
    });

    //datos del forgotpass
    const [code, setCode] = useState(0);
    const [newPass, setNewPass] = useState("");
    const [rePass, setRePass] = useState("");

    function setAllFalse (objectState) {
        //Set all constant in objectState as false
        for (const key in objectState) {
            objectState[key] = false;
        }
    }

    async function resetSubmit() {
        if (newPass === null || newPass.length < MIN_PASS_LENGTH) {
            setAllFalse(showState);
            setShowState((state) => ({ ...state, showPassLength: true }));
            return;
        }
        if (newPass !== rePass) {
            setAllFalse(showState);
            setShowState((state) => ({ ...state, showPassMatch: true }));
            return;
        }
        try {
            setAllFalse(showState);

            await Auth.completeNewPassword(
                currentUser, newPass,
                {
                    name: currentUser.challengeParam.userAttributes.name,
                    family_name: currentUser.challengeParam.userAttributes.family_name,
                });
        } catch (err) {
            console.log("error", err);
            if (err != null && err.code != null) {
                switch (err.code) {
                    case "ExpiredCodeException":
                        await Auth.forgotPassword(userEmail);
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showExpiredCodeException: true }));
                        break;
                    case "LimitExceededException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showLimitExceededException: true }));
                        break;
                    case "UserNotFoundException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showUserNotFoundException: true }));
                        break;
                    case "InvalidParameterException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showInvalidParameterException: true }));
                        break;
                    case "CodeMismatchException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showInvalidCode: true }));
                        break;
                    default:
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showDefaultError: true }));
                        break;
                }
            }
        }
    }

    async function handleChange(name, value) {
        switch (name) {
            case "usuario":
                if (value === null || value === "") {
                    value = " ";
                    await setUser(value);
                } else {
                    await setUser(value);
                }
                break;
            case "contraseña":
                await setPassword(value);
                break;
            case "code":
                if (value === null || value === "" || value === undefined) {
                    value = " ";
                    await setCode(value);
                } else {
                    await setCode(value);
                }
                break;
            case "newpassword":
                if (value === null || value === "") {
                    value = " ";
                    await setNewPass(value);
                } else {
                    await setNewPass(value);
                }
                break;
            case "repassword":
                if (value === null || value === "") {
                    value = " ";
                    await setRePass(value);
                } else {
                    await setRePass(value);
                }
                break;
            default:
                console.log("handlechange error");
        }
    }

    async function showForgot() {
        if (userEmail === null || userEmail === " ") {
            setShowUserState((state) => ({ ...state, showMsgEmail: true }));
        } else {
            try {
                await Auth.forgotPassword(userEmail);
                setShowLoginState((state) => ({ ...state, showLogin: false, showLoginForgot: true }));
                setShowState((state)=>({ ...state, showCodeSent: true}));
                setShowUserState((state) => ({ ...state, showInvalidUser: false }));

            } catch (err) {
                console.error(err);
                switch (err.code) {
                    case "LimitExceededException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showLimitExceededException: true }));
                        break;
                    case "UserNotFoundException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showUserNotFound: true, showUserNotFoundException: true }));
                        break;
                    case "InvalidParameterException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showInvalidParameterException: true }));
                        break;
                    case "CodeMismatchException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showPassMatch: true }));
                        break;
                    default:
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showDefaultError: true }));
                        break;
                }
            }
        }
    }

    async function signIn(prov) {
        switch (prov) {
            case "google":
                globalThis.sessionStorage.setItem("loginUrl", globalThis.location.toString());
                await Auth.federatedSignIn({ provider: "Google" })
                    .catch((err) => {
                        console.error(err);
                    });
                break;
            default:
                try {
                    if (userEmail === " " || userEmail === "" || userEmail === null || userEmail === undefined) {
                        setAllFalse(showUserState);
                        setShowUserState((state) => ({ ...state, showMsgEmail: true }));

                    } else if (userPass === " " || userPass === "" || userPass === null || userPass === undefined) {
                        setAllFalse(showUserState);
                        setShowUserState((state) => ({ ...state, showMsgPass: true }));
                    } else {

                        const user = await Auth.signIn(userEmail, userPass);
                        setCurrentUser(user);
                        if (user.challengeName === "SMS_MFA" ||
              user.challengeName === "SOFTWARE_TOKEN_MFA") {
                            console.log("You need to get the code from the UI inputs");
                            // You need to get the code from the UI inputs
                            // and then trigger the following function with a button click
                            const code = ""/* getCodeFromUserInput() */;
                            // If MFA is enabled, sign-in should be confirmed with the confirmation code
                            Auth.confirmSignIn(
                                user,   // Return object from Auth.signIn()
                                code,   // Confirmation code
                                user.challengeName === "SMS_MFA" ? "SMS_MFA" : "SOFTWARE_TOKEN_MFA"// MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
                            );
                        } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                            setShowLoginState((state) => ({ ...state, showLogin: false}));
                            setShowPassForNewUser(true);

                        } else if (user.challengeName === "MFA_SETUP") {
                            // This happens when the MFA method is TOTP
                            // The user needs to setup the TOTP before using it
                            // More info please check the Enabling MFA part

                            //Auth.setupTOTP(user);
                        }
                        console.log("Correct user");
                    }
                } catch (err) {
                    console.error(err);
                    if (err != null && err.code != null) {
                        switch (err.code) {
                            case "ExpiredCodeException":
                                await Auth.forgotPassword(userEmail);
                                setAllFalse(showState);
                                setShowState((state) => ({ ...state, showExpiredCodeException: true }));
                                break;
                            case "LimitExceededException":
                                setAllFalse(showState);
                                setShowState((state) => ({ ...state, showLimitExceededException: true }));
                                break;
                            case "UserNotFoundException":
                                setAllFalse(showState);
                                setShowState((state) => ({ ...state, showUserNotFoundException: true }));
                                break;
                            case "InvalidParameterException":
                                setAllFalse(showState);
                                setShowState((state) => ({ ...state, showInvalidParameterException: true }));
                                break;
                            case "CodeMismatchException":
                                setAllFalse(showState);
                                setShowState((state) => ({ ...state, showInvalidParameterException: true }));
                                break;
                            case "NotAuthorizedException":
                                setAllFalse(showUserState);
                                setShowUserState((state) => ({ ...state, showInvalidUser: true }));
                                setShowState((state) => ({ ...state, showPassChanged: false }));

                                break;
                            default:
                                setAllFalse(showState);
                                setShowState((state) => ({ ...state, showDefaultError: true }));
                                break;
                        }
                    }
                }
                break;
        }
    }

    async function forgotSubmit() {
        if (code === null || code === "" || code === " " || code === undefined || code === 0) {
            setShowState((state) => ({ ...state, showUserNotFound: false,
                showCodeSent: false,
                showInvalidCode: false,
                showPassLength: false,
                showCodeException: false,
                showEnterCode: true,
                showPassChanged: false }));
            return;
        }
        if (code !== null && code !== "" && code.length > 0 && isNaN(code)) {
            setShowState((state) => ({ ...state, showUserNotFound: false,
                showCodeSent: false,
                showInvalidCode: true,
                showPassLength: false,
                showCodeException: false,
                showEnterCode: false,
                showPassChanged: false }));
            return;
        }
        if (newPass === null || newPass.length < MIN_PASS_LENGTH) {
            setShowState((state) => ({ ...state, showUserNotFound: false,
                showCodeSent: false,
                showInvalidCode: false,
                showPassLength: true,
                showCodeException: false,
                showEnterCode: false,
                showPassChanged: false }));
            return;
        }
        if (newPass !== rePass) {
            setShowState((state) => ({ ...state, showUserNotFound: false,
                showCodeSent: false,
                showInvalidCode: false,
                showPassLength: false,
                showCodeException: false,
                showEnterCode: false,
                showPassChanged: false,
                showPassMatch: true }));
            return;
        }

        try {
            await Auth.forgotPasswordSubmit(userEmail, code, newPass);
            setShowLoginState((state) => ({ ...state, showLogin: true, showLoginForgot: false }));
            setAllFalse(showState);
            setShowState((state) => ({ ...state, showPassChanged: true }));

        } catch (err) {
            console.error(err);
            if (err != null && err.code != null) {
                switch (err.code) {
                    case "ExpiredCodeException":
                        await Auth.forgotPassword(userEmail);
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showExpiredCodeException: true }));
                        break;
                    case "LimitExceededException":
                        setAllFalse(showState);
                        setShowState((state) => ({...state, showLimitExceededException: true}));
                        break;
                    case "UserNotFoundException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showUserNotFoundException: true }));
                        break;
                    case "InvalidParameterException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showInvalidParameterException: true }));
                        break;
                    case "CodeMismatchException":
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showCodeException: true }));
                        break;
                    default:
                        setAllFalse(showState);
                        setShowState((state) => ({ ...state, showDefaultError: true}));

                        //SerializationException
                        break;
                }
            }
        }
    }

    function handleKeyDown(key) {
        if (key === "Enter") {
            signIn();
        }
    }

    return(
        <div className="min-h-screen flex flex-column align-items-center justify-content-center">
            <div className="border-1 surface-border surface-card border-round py-7 px-4 md:px-7 z-1 w-full md:w-28rem ">
                <div className="flex justify-content-center mb-4">
                    <img src="img/wiseconn.png" alt="wiseconn" width="250" />
                </div>
                {showLoginState.showLogin ?
                    <div className="flex flex-column" onKeyDown={(e) => handleKeyDown(e.key)}>
                        <span className="p-input-icon-left w-full mb-4">
                            <i className="pi pi-user" />
                            <InputText name= 'usuario' id='usuario' type="email" className="w-full" value={userEmail} placeholder={t("login.pHolderUser")} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                        </span>
                        <span className="p-input-icon-left w-full mb-4">
                            <i className="pi pi-lock" />
                            <InputText name='contraseña' id='contraseña' type="password" className="w-full" placeholder={t("login.pHolderPass")} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                        </span>
                        <div className="mb-4">
                            <a className="text-600 cursor-pointer hover:text-primary cursor-pointer ml-auto transition-colors transition-duration-300" onClick={showForgot}>
                                {t("login.forgot")}
                            </a>
                        </div>
                        <Button label={t("login.signin")} className="w-full" onClick={signIn} />
                        <Button label={t("login.withGoogle")} className="w-full mt-4" icon="pi pi-google" onClick={() => signIn("google")} />
                        <div className="flex justify-content-center">
                            <div id='loginForm_validation' className='errores'>
                                { }
                                {showUserState.showInvalidUser ? t("login.NotAuthorizedException") : null}
                                {showUserState.showMsgEmail ? t("login.enterMsgEmail") : null}
                                {showUserState.showMsgPass ? t("login.enterMsgPass") : null}
                                {showState.showCodeException ? t("login.CodeMismatchException") : null}
                                {showState.showExpiredCodeException ? t("login.ExpiredCodeException") : null}
                                {showState.showLimitExceededException ? t("login.LimitExceededException") : null}
                                {showState.showInvalidParameterException ? t("login.InvalidParameterException") : null}
                                {showState.showUserNotFoundException ? t("login.UserNotFoundException") : null}
                                {showState.showDefaultError ? t("login.defaultError") : null}
                                {showState.showPassChanged ? t("login.msgPassChanged") : null}
                            </div>
                        </div>
                    </div>:null}

                {showLoginState.showLoginForgot?
                    <div className="flex flex-column">
                        <span className="p-input-icon-left w-full mb-4">
                            <i className="pi pi-user" />
                            <InputText
                                name= 'usuario' id='usuario' type="email" className="w-full" value={userEmail}
                                placeholder={t("login.pHolderUser")}
                                onChange={(e) => handleChange(e.target.name, e.target.value)} disabled
                            />
                        </span>
                        <span className="p-input-icon-left w-full mb-4">
                            <i className="pi pi-lock" />
                            <InputText
                                name='code' id='code' type="text" className="w-full"
                                placeholder={pHolderCode}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                            />
                        </span>
                        <span className="p-input-icon-left w-full mb-4">
                            <i className="pi pi-lock" />
                            <InputText
                                name='newpassword' id='newpassword' type="password" className="w-full"
                                placeholder={pHolderPass}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                            />
                        </span>
                        <span className="p-input-icon-left w-full mb-4">
                            <i className="pi pi-lock" />
                            <InputText
                                name='repassword' id='repassword' type="password" className="w-full"
                                placeholder={pHolderPassConfirm}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                            />
                        </span>
                        <Button label={changePassword} className="w-full" onClick={forgotSubmit} />
                        <div className="flex justify-content-center">
                            <div id='loginForm_validation' className='errores'>
                                {}
                                {showState.showUserNotFound ? t("login.UserNotFoundException") : null}
                                {showState.showCodeSent ? t("login.msgForgotCodeSent") : null}
                                {showState.showEnterCode ? t("login.enterMsgCode") : null}
                                {showState.showInvalidCode ? t("login.msgInvalidCode") : null}
                                {showState.showPassLength ? t("login.msgPassLength") : null}
                                {showState.showPassMatch ? t("login.msgPassMatch") : null}
                                {showState.showCodeException ? t("login.CodeMismatchException") : null}
                                {showState.showExpiredCodeException ? t("login.ExpiredCodeException") : null}
                                {showState.showLimitExceededException ? t("login.LimitExceededException") : null}
                                {showState.showInvalidParameterException ? t("login.InvalidParameterException") : null}
                                {showState.showUserNotFoundException ? t("login.UserNotFoundException") : null}
                                {showState.showDefaultError ? t("login.defaultError") : null}
                            </div>
                        </div>

                    </div>:null}
                {showPassForNewUser?
                    <div className="flex flex-column">
                        <span className="p-input-icon-left w-full mb-4">
                            <i className="pi pi-user" />
                            <InputText
                                name= 'usuario' id='usuario' type="email" className="w-full" value={userEmail}
                                placeholder={t("login.pHolderUser")}
                                onChange={(e) => handleChange(e.target.name, e.target.value)} disabled
                            />
                        </span>
                        <span className="p-input-icon-left w-full mb-4">
                            <i className="pi pi-lock" />
                            <InputText
                                name='newpassword' id='newpassword' type="password" className="w-full"
                                placeholder={pHolderPass}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                            />
                        </span>
                        <span className="p-input-icon-left w-full mb-4">
                            <i className="pi pi-lock" />
                            <InputText
                                name='repassword' id='repassword' type="password" className="w-full"
                                placeholder={pHolderPassConfirm}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                            />
                        </span>
                        <Button label={changePassword} className="w-full" onClick={resetSubmit} />
                        <div className="flex justify-content-center">
                            <div id='loginForm_validation' className='errores'>
                                {}
                                {showState.showPassLength ? t("login.msgPassLength") : null}
                                {showState.showPassMatch ? t("login.msgPassMatch") : null}
                                {showState.showDefaultError ? t("login.defaultError") : null}
                            </div>
                        </div>

                    </div>:null}
            </div>
        </div>
    );
};

export default Login;
