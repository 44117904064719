// check wiseconn-env-gen.js for build envs
const region = process.env.REACT_APP_REGION ?? "us-east-1";
const endpoint = process.env.REACT_APP_ENDPOINT ?? `https://dkd0a7u293.execute-api.${region}.amazonaws.com/test`;
const domain = process.env.REACT_APP_DOMAIN ?? (
    process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://our.domain.com"
);

const wise_exports =  {
    "aws_project_region": region,
    "aws_cloud_logic_custom": [
        {
            "name": "apiApplication",
            "endpoint": endpoint,
            "region": region
        }
    ],
    "Auth": {
        "userPoolId": `${region}_Izgq043do`,
        "userPoolWebClientId": "2fc9aq88t99h9s7oue74tl9stc",
        "region": region,
        "oauth": {
            "domain": "auth.wiseconn.com",
            "redirectSignIn": domain,
            "redirectSignOut": domain,
            "responseType": "code"
        }
    }
};

export default wise_exports;
