import { Await, useLoaderData, useParams } from "react-router-dom";

import { ProgressSpinner } from "primereact/progressspinner";
import { Suspense, useEffect } from "react";

const Redirect = ({ to }) => {
    useEffect(() => {
        globalThis.sessionStorage.removeItem("return_page");
        globalThis.location.href = to;
    }, []);

    return null;
};

const Main = () => {
    const { promise } = useLoaderData();
    const { site } = useParams();
    const domain = site === "sgi" ? "https://ims.helpdocsonline.com" : "https://kb.dropcontrol.com";

    return (
        <>
            <div>
                <ProgressSpinner />
                <Suspense fallback={null}>
                    <Await
                        resolve={promise}
                        children={(resolvedToken) => {
                            let return_url = domain + "/access/jwtsso/?token=" + resolvedToken.message.token;
                            if (globalThis.sessionStorage.getItem("return_page")) {
                                return_url = return_url + "&page=" + globalThis.sessionStorage.getItem("return_page");
                            }
                            return <Redirect to={return_url} />;
                        }}
                    />
                </Suspense>
            </div>
        </>
    );
};

export default Main;
