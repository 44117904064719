import { useEffect, useMemo, useRef, useState } from "react";
import { Auth, appendToCognitoUserAgent } from "@aws-amplify/auth";

import { Logger, Hub } from "@aws-amplify/core";
import { AuthenticatorContext } from "../contexts/AuthenticatorContext";
const logger = new Logger("withAuthenticator");

const getEmailParam = (location) => {
    const search = new URLSearchParams(location && location.search);
    return search.get("email");
};
const getLogoutParam = (location) => {
    const search = new URLSearchParams(location && location.search);
    return search.get("logout");
};

// Mostly taken from
// https://github.com/aws-amplify/amplify-js/blob/aws-amplify%403.3.11/packages/amplify-ui-react/src/withAuthenticator.tsx
export default function AppWithAuthenticator({ children }) {
    const emailParam = getEmailParam(globalThis.location);
    const logoutParam =getLogoutParam(globalThis.location);
    const dcEmail = useRef(emailParam);
    const dcLogout = useRef(logoutParam);
    const [signedInInfo, setSignedInInfo] = useState({ info: {}, in: false, dcEmail: emailParam });
    const userInfoRef = useRef({});
    if (userInfoRef.current !== signedInInfo.info) userInfoRef.current = signedInInfo.info;

    const { setUser, checkUser } = useMemo(() => {
        const setUser = async (bypassCache) => {
            try {
                const user = await Auth.currentAuthenticatedUser({ bypassCache });
                if (user) {
                    const attributes = user?.signInUserSession?.idToken?.payload;
                    const thisEmail = attributes && attributes.email;
                    if (dcEmail.current && dcEmail.current !== thisEmail || dcLogout.current == 1) {
                        Auth.signOut();
                    } else if (attributes && ((attributes.sub && userInfoRef.current.sub !== attributes.sub)
                                || (attributes.name && userInfoRef.current.name !== attributes.name)
                                || (attributes.family_name && userInfoRef.current.family_name !== attributes.family_name))) {
                        setSignedInInfo({ info: { ...attributes }, in: true, dcEmail: null });
                    } else {
                        setSignedInInfo((si) => si.in ? si : { ...si, in: true, dcEmail: null });
                    }
                    dcEmail.current = null;
                    dcLogout.current = null;
                    globalThis.document.body.classList.remove("all");
                }
            }
            catch (err) {
                logger.debug(err);
            }
        };

        const checkUser = () => {
            setUser();
            return Hub.listen("auth", (info) => {
                const { payload } = info;
                const { event, data } = payload || {};

                if (event === "signOut") {
                    setSignedInInfo((si) => ({ info: {}, in: false, dcEmail: si.dcEmail }));
                } else if (event === "signIn") {
                    const { attributes } = data || {};
                    if (attributes && attributes.sub && userInfoRef.current.sub !== attributes.sub) {
                        setSignedInInfo({ info: { ...attributes }, in: true, dcEmail: null });
                    } else {
                        setSignedInInfo((si) => si.in ? si : { ...si, in: true, dcEmail: null });
                    }
                }
            });
        };
        return { setUser, checkUser };
    }, [setSignedInInfo]);

    useEffect(() => {
        appendToCognitoUserAgent("wiseconn withAuthenticator");

        // checkUser returns an "unsubscribe" function to stop side-effects
        return checkUser();
    }, [checkUser]);

    const contextValue = useMemo(() => {
        async function signOut() {
            try {
                globalThis.document.body.classList.remove("overflow-hidden");
                await Auth.signOut().then(console.log("Signed out"));
            } catch (err) {
                console.log("Unexpected error in signout");
            }
        }

        return { refreshUser: setUser, signOut, userInfo: signedInInfo.info };
    }, [setUser, signedInInfo]);

    const email = signedInInfo.info?.email;
    if (!email) {
        console.log("Go to Authenticate");
        return (
            <AuthenticatorContext.Provider value={null}>
                {children({ auth: false, dcEmail: signedInInfo.dcEmail })}
            </AuthenticatorContext.Provider>
        );
    }
    console.log("Go to Site");
    return (
        <AuthenticatorContext.Provider value={contextValue}>
            {children({ auth: true })}
        </AuthenticatorContext.Provider>
    );
}
